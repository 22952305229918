import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        className="insider-post"
        title="Oracle ACE Achievement"
        paragraph='Shabbir M Tahasildar, has been recognized as an Oracle ACE and included in the prestigious Oracle ACE Directory.'
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/elf_insider/Shabbir_oracle_ace.png" alt="Elfonze Unites for a Viksit Bharat!" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <p>
                          We are excited to announce that our associate, Shabbir M Tahasildar, has been recognized as an Oracle ACE and included in the prestigious Oracle ACE Directory. This honor reflects his outstanding contributions to the Oracle community and his deep expertise in the Oracle SCM Cloud space.
                            The Oracle ACE Program recognizes individuals who have demonstrated exceptional technical knowledge and leadership within the Oracle ecosystem. As a member of this esteemed group, Shabbir joins an exclusive network of Oracle professionals who actively share insights, best practices, and innovative solutions to drive the growth and success of the Oracle community.
                            Shabbir’s achievement is featured in the Oracle ACE Directory : <a href="https://apexapps.oracle.com/apex/ace/profile/changeme2587"> <strong className="text-white">Oracle ACE Directory Profile</strong></a>
                          </p>
                          <p>
                          We congratulate Shabbir on this well-deserved recognition and look forward to his continued contributions to both our organization and the Oracle community.
                          </p>
                          <div className="share-info">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                               
                            <a href="#0">#oracle</a>,
                            <a href="#0">#oraclescm</a>,
                            <a href="#0">#acedirectory</a>,
                            <a href="#0">#oraclecloud</a>,
                            <a href="#0">#achievements</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Shabbir Oracle Ace Associate</title>
      <meta key="description" name="description"
        content='We are excited to announce that our associate, Shabbir M Tahasildar, has been recognized as an Oracle ACE and included in the prestigious Oracle ACE Directory. This honor reflects his outstanding contributions to the Oracle community and his deep expertise in the Oracle SCM Cloud space.' />
    </>
  )
}

export default BlogDetailsDark;
